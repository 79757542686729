<template>
  <div>
    <!-- Listings -->
    <div class="row fs-listings" v-if="!loading">
      <!-- Listing Item -->
      <div class="col-lg-12 col-md-12" v-for="(shop, index) in shop_list" :key="index">
        <div class="listing-item-container list-layout" @mouseover="highlightMarker(index)" @mouseleave="notHighlightMarker(index)">
          <div class="listing-item">
            <!-- Image -->
            <div class="listing-item-image" @click="goPageShop(shop)">
              <img :src="shop.gallery.length > 0 ? config.backend_url+shop.gallery[0].value.path : require('@/assets/images/noimage.png')" alt="no-image">
              <span class="tag">{{ shop.mytypes[0].label }}</span>
            </div>
            
            <!-- Content -->
            <div class="listing-item-content">

              <div class="listing-item-inner" @click="goPageShop(shop)">
                <h3>{{ shop.label }}</h3>
                <div class="col-xs-12">
                  <span><i class="im im-icon-Map-Marker2"></i> {{ shop.addresses.filter(el => el.label === 'negozio')[0].geodata ? shop.addresses.filter(el => el.label === 'negozio')[0].geodata.formatted_address : 'Nessun indirizzo' }}</span>
                </div>
                <div class="col-xs-12">
                  <span v-if="shop.metas.contacts.value.telefono"><i class="im im-icon-Old-Telephone"></i> {{ shop.metas.contacts.value.telefono ? shop.metas.contacts.value.telefono : 'Non specificato' }}</span>
                </div>
                <div class="col-xs-12">
                  <span v-if="shop.metas.contacts.value.cellulare"><i class="fa fa-whatsapp"></i> {{ shop.metas.contacts.value.cellulare ? shop.metas.contacts.value.cellulare : 'Non specificato' }}</span>
                </div>
                <div class="col-xs-12">
                  <span v-if="shop.metas.contacts.value.email"><i class="im im-icon-Email"></i> {{ shop.metas.contacts.value.email ? shop.metas.contacts.value.email : 'Non specificato' }}</span>
                </div>
              </div>

              <span  v-if="$store.state.user.isUserLoggedIn" :class="($store.state.my_shops.my_shops.map(el => el.id)).includes(shop.id) ? 'like-icon liked' : 'like-icon'" @click="addRemoveFavoriteShop(shop)"></span>
            </div>
          </div>
        </div>
      </div>
      <!-- Listing Item / End -->
    </div>
    <!-- Listings Container / End -->
  </div>
</template>

<script>
import ListingService from '@/services/ListingService'
import config from '@/config'
export default {
  name: 'list',
  props: ['loading'],
  data() {
    return {
      shop_list: [],
      config: null
    }
  },
  mounted () {
    this.config = config
    console.log("shop_list")
    console.log(this.shop_list)
  },
  methods: {
    highlightMarker(index) {
      this.$eventHub.$emit('highlight', index)
    },
    notHighlightMarker(index) {
      this.$eventHub.$emit('not-highlight', index)
    },
    async addRemoveFavoriteShop (shop) {
      this.$eventHub.$emit('getting_result', 1)
      try {
        console.log('Shop_id: ', shop)
        // const user = await ListingService.addFavoriteShop({shop_id: shop.id})
        // console.log('new user: ', user)
        var user
        if ((this.$store.state.my_shops.my_shops.map(el => el.id)).includes(shop.id)) {
          user = await ListingService.removeFavoriteShop(shop.id)
          console.log('deleted shop user: ', user)
        } else {
          user = await ListingService.addFavoriteShop({shop_id: shop.id})
          console.log('new user: ', user)
        }
        this.$store.dispatch('my_shops/setMyShops', user.data.myshops)
        this.$eventHub.$emit('getting_result', 0)
      } catch(err) {
        console.log('error in addRemoveFavoriteShop(): ', err)
      }
    },
    goPageShop(shop) {
     // var url = "https://www.vetrineonline.com/shop?id="+shop.id
      //window.location.href = url
      
      this.$router.push({
        path: '/shop?id='+shop.id
      })
      
    }
  }
}
</script>

<style>

</style>