import Api from '@/services/Api'
import config from '@/config'

export default {
  getListShop(latitude, longitude, page, range, type, looking_for) {
    var params_url = '?paginate=true&rows_per_page='+config.pagination.rows_per_page+'&include=true'
    latitude ? params_url+='&latitude='+latitude : params_url
    longitude ? params_url+='&longitude='+longitude : params_url
    page ? params_url+='&page='+page : params_url+='&page=1'
    range ? params_url+='&range='+range : params_url
    type ? params_url+='&mytypes.id='+type : params_url
    looking_for && looking_for !== '' ? params_url+= '&label=|'+looking_for+'|' : params_url
    //console.log('params url: ',params_url)
    return Api().get('/model/shop'+params_url)
  },
  getListShopClassification( latitude, longitude, page, range, classification ) {
    var params_url = '?paginate=true&rows_per_page='+config.pagination.rows_per_page+'&include=true'
    latitude ? params_url+='&latitude='+latitude : params_url
    longitude ? params_url+='&longitude='+longitude : params_url
    page ? params_url+='&page='+page : params_url+='&page=1'
    range ? params_url+='&range='+range : params_url
    if ( classification.length > 0 ) {
      var cat = classification.join(',')
      console.log(cat)
      if( cat >= 49 && cat <= 61){
        params_url += `&classification.c=[${ classification.join(',') }]`
      }
      else{
        params_url += `&classification.u=[${ classification.join(',') }]`
      }
      
    }
    
    //console.log('params url: ',params_url)
    console.log(classification)
    return Api().get('/model/shop/v2'+params_url)
  },

  getListShopClassificationCat( latitude, longitude, page, range, classification ) {
    var params_url = '?paginate=true&rows_per_page='+config.pagination.rows_per_page+'&include=true'
    latitude ? params_url+='&latitude='+latitude : params_url
    longitude ? params_url+='&longitude='+longitude : params_url
    page ? params_url+='&page='+page : params_url+='&page=1'
    range ? params_url+='&range='+range : params_url
    if ( classification.length > 0 ) {
      console.log("qua")
      console.log(classification.join(','))
      var classification_list = classification.join(',')
      params_url += '&classification.c=[' + classification_list + ']'
    }
    //console.log('params url: ',params_url)
    //console.log(classification)
    return Api().get('/model/shop/v2'+params_url)
  },

  
  addFavoriteShop(shop_id) {
    return Api().post('/model/user/shop', shop_id)
  },
  removeFavoriteShop(shop_id) {
    return Api().delete('/model/user/shop/'+shop_id)
  }
}
