<template>
  <!-- Header Container
  ================================================== -->
  <div>
  <header id="header-container" :class="$route.path === '/shops-list' ? 'fixed fullwidth' : $route.path === '/shops-list' || $route.path === '/reset-password' ? 'fullwidth' : ''">

    <!-- Header -->
    <div id="header" :class="$route.path === '/shops-list' || $route.path === '/preview-myshop' || $route.path === '/shop' || $route.path === '/reset-password' ? 'not-sticky' : ''">
      <div class="container">
        
        <!-- Left Side Content -->
        <div class="left-side">
          
          <!-- Logo -->
          <div id="logo">
            <router-link to="/" v-if="$route.path === '/' || $route.path === '/home'"><img src="@/assets/images/Logo Vetrineonline.com.png" :data-sticky-logo="require('@/assets/images/Logo_VetrineOnline.com_ritagliato_trasparente.png')" alt=""></router-link>
            <router-link v-else to="/" ><img src="@/assets/images/Logo_VetrineOnline.com_ritagliato_trasparente.png" :data-sticky-logo="require('@/assets/images/Logo_VetrineOnline.com_ritagliato_trasparente.png')" alt=""></router-link>

            <!-- <router-link to="/" ><h4 style="color:#f91942;">Vetrine OnLine</h4></router-link> -->
          </div>
          
          <!-- Main Navigation -->
          <nav id="navigation" class="style-1">
            <!-- icone social user non autenticato e su mobile-->
            <a class="hidecomputer" href="https://www.facebook.com/vetrineonline" target="_blank" v-if="!this.$store.state.user.isUserLoggedIn"><img src="@/assets/images/face-icon2.png" alt="" style="width: 30px;margin-top:20px;margin-left:auto;"></a>
            <i style="margin-left:5px"></i>
            <a class="hidecomputer" href="https://www.instagram.com/vetrineonline/" target="_blank" v-if="!this.$store.state.user.isUserLoggedIn"><img src="@/assets/images/instagram02.png" alt="" style="width: 30px;margin-top:20px;margin-left:auto;"></a>
           
          </nav>
          <div class="clearfix"></div>
         
          <!-- Main Navigation / End -->
        </div>
        <!-- Left Side Content / End -->

        <!-- Right Side Content / End -->
        <div class="right-side">
          <div class="header-widget">
            <!-- User Menu -->
            <!--
            <div class="user-menu" v-if="this.$store.state.user.isUserLoggedIn">
              <div class="user-name"><i class="sl sl-icon-user" style="margin-right:10px;"></i>Ciao, {{ this.$store.state.user.user.first_name }}!</div>
              <ul>
                <li><router-link to="/dashboard-company-my-shop?shop=0"><i class="sl sl-icon-settings"></i> Dashboard</router-link></li>
                <li v-if="this.$store.state.user.user.role_id === 5 && this.$store.state.shop.shop"><router-link :to="'/shop?id='+this.$store.state.shop.shop.id"><i class="im  im-icon-Shop"></i> Mio Negozio</router-link></li>
                <li><a @click="logout()"><i class="sl sl-icon-power"></i> Logout</a></li>
              </ul>
            </div>
            -->
            
            <!--
            <a href="#sign-in-dialog" :class="$route.path === '/shops-list' || $route.path === '/preview-myshop' || $route.path === '/shop' || $route.path === '/reset-password' || $route.path === '/confirm-registration' || $route.path === '/all-shops' ? 'button sign-in sign-in_1 popup-with-zoom-anim custom_modal_log' : 'button sign-in popup-with-zoom-anim custom_modal_log'" v-if="!this.$store.state.user.isUserLoggedIn"><i class="sl sl-icon-login hidemobile"></i> Accedi</a>
            <a style="display: none" href="#small-dialog" :class="'button sign-in sign-in_1 popup-with-zoom-anim'" id="link-search" v-if="!this.$store.state.user.isUserLoggedIn"><i class="sl sl-icon-login hidemobile"></i> Cerca</a>
            <a href="#sign-in-dialog" :class="$route.path === '/shops-list' || $route.path === '/preview-myshop' || $route.path === '/shop' || $route.path === '/reset-password' || $route.path === '/confirm-registration' || $route.path === '/all-shops' ? 'button sign-in sign-in_1 popup-with-zoom-anim custom_modal_reg' : 'button sign-in popup-with-zoom-anim custom_modal_reg'" v-if="!this.$store.state.user.isUserLoggedIn"><i class="fa fa-sign-in hidemobile"></i> Registrazione</a>
            -->
            <!-- QUA NUOVE ICONE SOCIAL USER NON AUTENTICATO  -->
            <div style="display: inline; text-align:center" class="hidemobile">
              <a class="hidemobile" href="https://www.facebook.com/vetrineonline" target="_blank" v-if="!this.$store.state.user.isUserLoggedIn"><img src="@/assets/images/face-icon2.png" alt="" style="width: 25px;margin-top:20px;margin-left:10px"></a>
              <a class="hidemobile" href="https://www.instagram.com/vetrineonline/" target="_blank" v-if="!this.$store.state.user.isUserLoggedIn"><img src="@/assets/images/instagram02.png" alt="" style="width: 25px;margin-top:20px;margin-left:15px;"></a>
            </div>

          </div>
        </div>
        <!-- Right Side Content / End -->

            <!-- Sign In Popup -->
            <!--
            <div id="sign-in-dialog" class="zoom-anim-dialog mfp-hide">

                <div class="small-dialog-header">
                    <h3>Sign In</h3>
                </div>
                <div class="sign-in-form style-1">
                    <ul class="tabs-nav">
                        <li class=""><a id="a_log" href="#tab1">Accedi</a></li>
                        <li><a id="a_reg" href="#tab3">Registra Negoziante</a></li>
                        <li><a href="#tab2">Registra Utente</a></li>
                    </ul>

                    <div class="tabs-container alt">
  
                        <login class="tab-content" id="tab1" style="display: none;"></login>
 
                        <register-customer class="tab-content" id="tab2" style="display: none;"></register-customer>

                        <register-company class="tab-content" id="tab3" style="display: none;"></register-company>

                    </div>
                </div>
            </div>
            -->
            <!-- Sign In Popup / End -->

      </div>
    </div>
    <!-- Header / End -->

  </header>
  <div class="clearfix"></div>
  </div>
</template>

<script>


export default {
  name: 'header',
  components: {
  },
  data() { return { windowWidth: window.innerWidth, cat_id: null }; },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      //console.log( this.windowWidth );
    });
  },
  computed: {
  },
  methods: {
    test_function( obj ) {
        //console.log( obj );
        this.cat_id = obj.cat_id;
    },
  }
}
</script>

<style scoped>
  .sign-in:hover {
    /* color: #f91942 !important; */
    transition: 0.3s;
  }
  .sign-out {
    margin-left: 25px;
  }
  .sign-out:hover {
    cursor: pointer;
  }
  .sticky .sign-in {
    /* color: #333 !important; */
  }
  #sign-in-dialog {
    max-height: 700px;
    max-width: 600px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .not-sticky {
    background-color: #fff !important;
    background: #fff !important;
    box-shadow: 0 0 12px 0 rgba(0,0,0,.12) !important;
  }
  .not-sticky .user-name {
    color: #333 !important
  }
  .not-sticky .user-menu .user-name:after, .user-name-dashboard:after {
    color: #333 !important;
  }
  @media screen and (max-width: 500px) {
    .hidemobile {
      display: none;
    }
  }
  @media screen and (min-width: 500px) {
    .hidecomputer {
      display:  none;
    }
  }
  .to_dashboard_2 {
    color: #333 !important;
  }
  .to_dashboard_2:hover {
    color: white !important;
  }
  .not-sticky .current {
    background: rgba(249,25,66,.06) !important;
    color: #f91942 !important;
  }
  .sign-in_1 {
    color: #333 !important;
    border: 1px solid #333 !important;
  }face-icon2.png
Instagram02.png
  .sign-in_1:hover {
    color: white !important;
    border: 1px solid #f91942 !important;
  }
  @media only screen and (max-width: 991px) {
    .sign-in_1:nth-child(2) {
      /* margin-top: 10px; */
    }
  }
</style>
