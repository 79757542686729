<script>

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]

import ListingService from '@/services/ListingService'
import ClassificationCatList from './cat_list';

export default {

    name: 'classification',
    props: [],
    components: { ListingService, ClassificationCatList },
    data() {
        return {
            cat_id: null,
            categories: [], //categorie selezionate
           
        };
    },
    computed: {
        /*
        classification_loaded() { return this.$store.state.classification.obj === null ? false : true },
        */
        selected_categories: {
            get() { 
                this.$store.state.classification.selected_ids = this.categories
                return this.$store.state.classification.selected_ids },
            set( selected_ids ) { return this.$store.dispatch( 'classification/set_selected_ids', selected_ids ); },
        },
        
    },
    watch: {},
    mounted() {
        //this.selected_ids = this.categories
        this.observer = new MutationObserver(mutations => {
          for (const m of mutations) {
            const newValue = m.target.getAttribute(m.attributeName);
            this.$nextTick(() => {
              this.on_class_change(newValue, m.oldValue);
            });
          }
        });
        this.observer.observe(this.$refs['small_dialog'], {
          attributes: true,
          attributeOldValue : true,
          attributeFilter: ['class'],
        });
        if(this.categories.length > 0){
          this.getShopByCategory()
        }
    },
    methods: {
      
      async getShopByCategory(){
        console.log("classification search: categorie selezionate")
        this.$store.state.classification.selected_ids = this.categories
        console.log(this.$store.state.classification.selected_ids)
        const list_shops = await ListingService.getListShopClassificationCat(
          this.latitude,
          this.longitude,
          this.page,
          this.radius*1000,
          this.categories,
          null
        )
        list_shops.data.data = list_shops.data.data.filter(el => {
          return el.addresses.map(el2 => el2.label).includes('negozio')
        });
        this.$store.state.classification.shops = list_shops.data
        console.log("list_shops")
        console.log(list_shops.data.data)
        this.$emit( 'submit-search', 'page' )
      },
      on_class_change(classAttrValue) {
          const classList = classAttrValue.split(' ');
          if ( classList.includes('mfp-hide') ) {
              this.$emit( 'submit-search', 'page' )
          }
      },
      set_cat_id( obj ) {
          this.cat_id = obj.cat_id;
      },
    },
    created () {},

}

</script>

<template>
    <div>
        <div>
            <template>
                <div ref="small_dialog">
                    <ul style="padding-left:1px;padding-right:1px;">
                        <div class="row">
                          <li><input type="checkbox" v-bind:id="54" name="categories" v-bind:value="54" v-model="categories" @change="getShopByCategory()">
                            <label v-bind:for="54"><img src="@/assets/images/categorie/alimentari2.png" class="image-fill" style="width:63px;margin-right:2px"></label>
                          </li> 
                          
                          <li><input type="checkbox" v-bind:id="55" name="categories" v-bind:value="55" v-model="categories" @change="getShopByCategory()">
                            <label v-bind:for="55">
                          <img src="@/assets/images/categorie/auto-moto.png" class="image-fill" style="width:63px;margin-right:2px"></label>
                          </li>
                          <li><input type="checkbox" v-bind:id="58" name="categories" v-bind:value="58" v-model="categories" @change="getShopByCategory()">
                            <label v-bind:for="58">
                          <img src="@/assets/images/categorie/bellezza.png" class="image-fill" style="width:63px;margin-right:2px"></label>
                          </li>
                          <li><input type="checkbox" v-bind:id="52" name="categories" v-bind:value="52" v-model="categories" @change="getShopByCategory()">
                            <label v-bind:for="52"><img src="@/assets/images/categorie/cartoleria.png" class="image-fill" style="width:63px;margin-right:2px"></label>
                          </li>
                        </div>
                         <div class="row">
                            <li class="image-dritta"><input type="checkbox" v-bind:id="51" name="categories" v-bind:value="51" v-model="categories"  @change="getShopByCategory()">
                              <label v-bind:for="51"><img src="@/assets/images/categorie/casa.png" style="width:63px;margin-right:2px"></label>
                            </li>
                            <li class="image-dritta"><input type="checkbox" v-bind:id="48" name="categories" v-bind:value="48" v-model="categories" @change="getShopByCategory()">
                            <label v-bind:for="48"><img src="@/assets/images/categorie/elettronica.png" style="width:63px;margin-right:2px"></label>
                            </li>
                            <li class="image-dritta"><input type="checkbox" v-bind:id="57" name="categories" v-bind:value="57" v-model="categories" @change="getShopByCategory()">
                            <label v-bind:for="57"><img src="@/assets/images/categorie/food.png" style="width:63px;margin-right:2px"></label>
                            </li>
                            <li class="image-dritta"><input type="checkbox" v-bind:id="56" name="categories" v-bind:value="56" v-model="categories" @change="getShopByCategory()">
                              <label v-bind:for="56"><img src="@/assets/images/categorie/grande-distribuzione.png" style="width:63px;margin-right:2px"></label>
                            </li>
                        </div>
                        <div class="row">
                          <li class="image-dritta"><input type="checkbox" v-bind:id="53" name="categories" v-bind:value="53" v-model="categories" @change="getShopByCategory()">
                              <label v-bind:for="53"><img src="@/assets/images/categorie/moda.png" style="width:63px;margin-right:2px"></label>
                          </li>
                          <li class="image-dritta"><input type="checkbox" v-bind:id="59" name="categories" v-bind:value="59" v-model="categories" @change="getShopByCategory()">
                              <label v-bind:for="59"><img src="@/assets/images/categorie/servizi.png" style="width:63px;margin-right:2px"></label>
                          </li>
                          <li class="image-dritta"><input type="checkbox" v-bind:id="49" name="categories" v-bind:value="49" v-model="categories" @change="getShopByCategory()">
                              <label v-bind:for="49"><img src="@/assets/images/categorie/tempo-libero.png" style="width:63px;margin-right:2px"></label>
                          </li>
                          <li class="image-dritta"><input type="checkbox" v-bind:id="50" name="categories" v-bind:value="50" v-model="categories" @change="getShopByCategory()">
                              <label v-bind:for="50"><img src="@/assets/images/categorie/turismo.png" style="width:63px;margin-right:2px"></label>
                          </li>
                        </div>
                      </ul>
                </div>
            </template>
        </div>
    </div>

</template>


<style>
 ul {
    list-style-type: none;
  }

  li {
    display: inline-block;
  }

  input[type="checkbox"] {
    display: none;
  }

  label {
    padding: 0.5px;
    display: block;
    position: relative;
    margin: 3px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  label::before {
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  label img {
    transition-duration: 0.2s;
  }

  .image-fill{
    filter: grayscale(60%);
    width: 70px;
    text-align: center;
  }
  .image-dritta{
    text-align: center;
  }
  :checked+label::before {
    content: "✓";
    background-color: #e10303;
    transform: scale(1);
  }

  :checked+label img {
    transform: scale(0.95);
    z-index: -1;
    filter: none;
  }
  :hover.imageblock {
    opacity:0.7;
    box-shadow: 5px 5px #b7b7b7;
  }
  input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
</style>

