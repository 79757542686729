<template>
  <GmapMap
    ref="map"
    :center="center"
    :zoom="zoom_computed"
    style="width: 100%; height: 100%"
    :options="map_options"
    @drag="foo"
  >
    <GmapMarker
      v-if="getIsCenter"
      :position="center"
      :clickable="true"
      :draggable="true"
      icon="http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
      @drag="updateCoordinates"
      :animation="2"
    />
    <!--<GmapCircle
      v-if="!dragging && getIsCenter"
      :center="center"
      :radius="radius"
      :visible="true"
      :options="{fillOpacity:.2}"
    ></GmapCircle>-->
    <div v-if="shops_list.length >0">
      <GmapMarker
        :key="index"
        v-for="(shop, index) in shops_list"
        :position="shop.position"
        :clickable="true"
        :animation="animation['marker_animation_'+index]"
        :ref="'marker_'+index"
        @click="toggleInfoWindow(shop,index)"
      />
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen=false"
      >
        <div v-html="infoContent"></div>
      </gmap-info-window>
    </div>
  </GmapMap>
</template>

<script>
import {loaded} from 'vue2-google-maps';
export default {
  name: 'google-maps',
  data() {
    return {
      center: { lat:45.219865, lng:12.279014},//<-coordinate chioggia. 
      zoom: 11,
      isCenter: true,
      map_options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      },
      dragging: false,
      shops_list: [],
      animation: {},
      radius: 500,
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
    }
  },
  computed: {
    zoom_computed: function () {
      // if (this.radius <= 3000)
      //   return 16-(this.radius/1000)
      // else if (this.radius > 3000 && this.radius <= 5000)
      //   return 16-((this.radius/1000)-1)
      // else if (this.radius >= 6000 && this.radius <= 10000)
      //   return 12
      // else return 11
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        var scale = this.radius / 500;
        var zoomLevel =(16 - Math.log(scale) / Math.log(2))
        return zoomLevel-1
      } else {
        var scale = this.radius / 500;
        var zoomLevel =(16 - Math.log(scale) / Math.log(2))
        return zoomLevel
      }
    },
    set_animations: function() {
      var vm = this
      return function (index, data) {
        vm.animation['marker_animation_'+index] = data
        this.get_animations
        return vm.animation
      }
    },
    get_animations: function() {
      for (let i = 0; i<this.shops_list.length; i++) {
        this.animation['marker_animation_'+i] = 2
      }
      return this.animation
    },
    getIsCenter: function() {
      return this.isCenter
    }
  },
  created: function() {
    this.$eventHub.$on('not-highlight', this.notHighlight)
    this.$eventHub.$on('highlight', this.highlight)
  },
  beforeDestroy () {
      this.$eventHub.$off('not-highlight', this.notHighlight)
      this.$eventHub.$off('highlight', this.highlight)
  },
  mounted () {
    navigator.geolocation.getCurrentPosition(position => {
      //console.log('position: ', position)
    });
    for (let i = 0; i<this.shops_list.length; i++) {
      this.animation['marker_animation_'+i] = 2
    }
  },
  methods: {
    highlight(index) {
      //console.log('mouse over on: ', index, this.$refs)
      this.$refs['marker_'+index][0].animation = 1
      //this.animations['marker_animation_'+index] = 1
      // console.log('pre animation: ',this.animation)
      // console.log('pre refs: ',this.$refs)
      // this.set_animations(index, 1)
      // console.log('post animation: ',this.animation)
      // console.log('post refs: ',this.$refs)
      //this.animation = 1
    },
    notHighlight(index) {
      //console.log('mouse NOT over on: ', index, this.$refs)
      //this.animation['marker_animation_'+index] = 0
      this.$refs['marker_'+index][0].animation = 0
      // this.set_animations(index, 0)
    },
    foo() {
      // dobbiamo prendere le coordinate (del centro mappa o altro) dopo lo spostamento
      navigator.geolocation.getCurrentPosition(position => {
        console.log('mi sto muovendo: ', position)
      });
    },
    updateCoordinates(location) {
      this.dragging = true
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
      this.dragging=false
      
    },
    getIcon() {
      return require('../../assets/images/icons-map/rsz_icona-macelleria-1.png')
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = this.getInfoWindowContent(marker);
      

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getInfoWindowContent: function (marker) {
      //var url_shop= '/shop?id='+this.$store.state.shop.shop.id
      return (
        `<div class="card">
          <div class="card-image">
            <figure class="image is-4by3">
              <img src="${marker.image}" alt="Shop image" style="width: 150px; height: auto;">
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="custom_p"><a href="/shop?id=${marker.id}">${marker.name}</a></p>
              </div>
            </div>
            <div class="content">
              <i>${marker.type}</i>
            </div>
          </div>
        </div>`
      );
    }
  }
}
</script>

<style>
  .custom_p {
    font-size: 16px;
    color: #333;
    font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .gm-ui-hover-effect {
    top: 0!important;
    right: 0!important;
    background: #f91942 !important;
    border: 2px solid #f91942 !important;
    border-radius: 20px !important;
    width: 20px !important;
    height: 20px !important;
  }
  .gm-ui-hover-effect img {
    margin: 1.2px !important;
  }
</style>
