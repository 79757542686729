<script>

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]

export default {

    name: 'classification-cat-list',
    props: [ 'cat_id', 'input_categories' ],
    data() {
        return {
            active   : null,
            list_key : 1,
        };
    },
    computed: {
      sectors() { return this.$store.getters['classification/sectors'] || [] },
      categories() { [ 54, 55, 58, 52, 51, 48, 57, 56, 53, 59, 49, 50 ] },//return this.$store.getters['classification/categories'] || [] },
    },
    watch: {
        categories( newvalue, oldvalue ) {
            if ( newvalue && newvalue.length > 0 ) {
              this.set_active( newvalue[0].id );
            }
        }
    },
    methods: {
      set_active( cat_id ) {
        if ( cat_id === this.active ) { return; }
        this.active = cat_id;
        this.$emit( 'set-category', { cat_id: this.active } );
      },
      is_selected( cat_id ) {
        if ( this.input_categories && Array.isArray( this.input_categories ) ) {
            return this.input_categories.includes( cat_id );
        }
        return false;
      },
      get_style( cat_id ) {
          var style = {
              'background-color' : 'lightgrey',
              'border'           : '2px solid white',
              'color'            : 'grey',
              'cursor'           : 'pointer',
          };
          if ( this.is_selected( cat_id ) ) {
              style['background-color'] = 'red';
              style['color'] = 'white';
          }
          if ( this.active === cat_id ) {
              style['border'] = '2px solid black';
              //style['cursor'] = 'default'
          }
          return style;
        },
    },
    created () {
        if ( this.cat_id ) { this.active = this.cat_id; }
    },

}

</script>

<template>
    <div  style="height: 405px; overflow-y: auto">
        <div
            style="padding: 6px; padding-left: 12px; margin-top: 12px; color: black; border-radius: 20px; width: 90%; font-weight: bold;"
            :style="get_style( cat.id )"
            v-for="cat in categories"
            :key="cat.id"
            @click="set_active( cat.id )"
            >
            {{ cat.name }}
        </div>
    </div>

</template>


<style>
</style>

